<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div>
        <el-row>
          <el-form-item :label="$t('title.Platformsite')" prop="site">
            <el-radio-group v-model="form.site" @change="PlatformsiteChange">
              <el-radio label="">{{ $t("title.OrderAll") }}</el-radio>
              <el-radio
                v-for="item in terraceData"
                :key="item.id"
                v-model="form.site"
                :label="item.siteCode"
              >{{ item.siteName }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('title.PlatformAccount')" prop="shopCode">
            <el-radio-group
              v-model="form.shopCode"
              @change="PlatformAccountChange"
            >
              <el-radio label="">{{ $t("title.OrderAll") }}</el-radio>
              <el-radio
                v-for="(item,index) in PlatformAccountList"
                :key="index"
                :label="item.accountName"
              >{{ item.accountName }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('title.Category')" prop="commentType">
            <el-radio-group v-model="form.commentType" @change="commentTypeChange">
              <el-radio label="">{{ $t("title.OrderAll") }}</el-radio>
              <el-radio label="Negative">Negative</el-radio>
              <el-radio label="Neutral">Neutral</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="space-between">
          <div style="width: calc(100% - 250px)">
            <el-row class="Row-Input" :class="{ 'show-row': showRow }">
              <!-- 消息id -->
              <el-col :span="8">
                <el-form-item label="Item ID" prop="itemId">
                  <el-input
                    v-model="form.itemId"
                    :placeholder="$t('page.inputPlaceholder')"
                  />
                </el-form-item>
              </el-col>

              <!-- Email -->
              <el-col :span="8">
                <el-form-item label="Buyer ID" prop="buyerId">
                  <el-input
                    v-model="form.buyerId"
                    :placeholder="$t('page.inputPlaceholder')"
                  />
                </el-form-item>
              </el-col>

              <!-- 平台订单号 -->
              <el-col :span="8">
                <el-form-item :label="$t('title.platNumber')" prop="orderCode">
                  <el-input
                    v-model="form.orderCode"
                    :placeholder="$t('page.inputPlaceholder')"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item :label="$t('title.Shippingwarehouses')" prop="shippingWarehouse">
                  <el-select v-model="form.shippingWarehouse" clearable filterable :placeholder="$t('page.selectPlaceholder')">
                    <el-option
                      v-for="item in shippingWarehouseDatas"
                      :key="item.shippingWarehouseCode"
                      :label="item.shippingWarehouseCode"
                      :value="item.shippingWarehouseCode"
                    >
                      <span>{{ item.shippingWarehouseCode }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.shippingWarehouseCode === 'US0003'?item.shippingWarehouseName.split("-")[1] : item.shippingWarehouseCode === 'USKY3' ? item.shippingWarehouseName.split("-")[1] : '' }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 评论时间 -->
              <el-col :span="12">
                <el-form-item :label="$t('title.Reviewed')" prop="date">
                  <el-date-picker
                    v-model="form.date"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="~"
                    :start-placeholder="$t('title.starttime')"
                    :end-placeholder="$t('title.endtime')"
                    :unlink-panels="true"
                    :default-time="['00:00:00', '23:59:59']"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24" style="display:flex;height:36px;">
                <el-button @click="handleExport">{{ $t('page.export') }}</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row style="width: 220px">
            <el-col class="row-center">
              <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
              <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
              <el-button type="text" @click="showRow = !showRow">{{ showRow ? $t("page.hide") : $t("page.expand") }}</el-button>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="site"
        :label="$t('title.site')"
        width="100"
        align="center"
        sortable
      />

      <el-table-column
        prop="shopCode"
        :label="$t('title.shopName')"
        width="130"
        align="center"
        sortable
      />
      <el-table-column
        prop="commentType"
        :label="$t('title.types')"
        width="100"
        align="center"
        sortable
      />
      <el-table-column
        prop="itemId"
        label="Item ID"
        width="120"
        align="center"
        sortable
      />
      <el-table-column
        prop="buyerId"
        label="buyer ID"
        align="center"
        width="120"
        sortable
      />
      <el-table-column
        prop="orderCode"
        :label="$t('title.platNumber')"
        width="140"
        align="center"
        sortable
      />
      <el-table-column
        prop="shippingWarehouse"
        :label="$t('title.Shippingwarehouses')"
        width="90"
        align="center"
        sortable
      />

      <el-table-column
        prop="sendDate"
        :label="$t('title.Deliverytime')"
        width="140"
        align="center"
        sortable
      />

      <el-table-column
        prop="commentTime"
        :label="$t('title.Reviewed')"
        align="center"
        width="140"
        sortable
      />
      <el-table-column sortable align="center" prop="commentText" label="Feedback">
        <template slot-scope="scope">
          <el-popover placement="top-start" width="300" trigger="hover" :disabled="scope.row.commentText.length <= 100">
            <div>{{ scope.row.commentText }}</div>
            <span v-if="scope.row.commentText.length <= 100" slot="reference">{{ scope.row.commentText }}</span>
            <span v-if="scope.row.commentText.length > 100" slot="reference">{{ scope.row.commentText.substr(0, 100) + "..." }}</span>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[10,20, 50, 100, 200, 300]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import { querySiteList, listAllAccount, getFeedbackInfoByPages, getShippingWarehouse, exportFeedbackInfos } from '@/api/service-management'
export default {
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      TableLoading: false,
      tableDatas: [],
      showRow: false,
      terraceData: [],
      shippingWarehouseDatas: [],
      PlatformAccountList: [],
      platform: 'ebay',
      form: {
        buyerId: '',
        site: 'US',
        commentType: '',
        itemId: '',
        orderCode: '',
        shippingWarehouse: '',
        shopCode: '',
        date: []
      }
    }
  },
  computed: {
    queryParameter() {
      const [commentStartTime = '', commentEndTime = ''] = this.form.date || []
      return Object.assign({}, this.form, this.pager, { commentStartTime, commentEndTime })
    },
    exportUrl() {
      const [commentStartTime = '', commentEndTime = ''] = this.form.date || []
      const { buyerId, site, commentType, itemId, orderCode, shippingWarehouse, shopCode } = this.form
      return `${exportFeedbackInfos}?buyerId=${buyerId}&site=${site}&orderCode=${orderCode}&commentStartTime=${commentStartTime}&commentEndTime=${commentEndTime}&itemId=${itemId}&commentType=${commentType}&shippingWarehouse=${shippingWarehouse}&shopCode=${shopCode}`
    }
  },

  mounted() {
    this._querySiteList()
    this.getmailaccountinfo()
    this._getFeedbackInfoByPages()
    this._getShippingWarehouse()
  },
  methods: {
    // 导出文件
    handleExport() {
      window.open(this.exportUrl, '_blank')
    },
    // 查询发货仓库
    async _getShippingWarehouse() {
      const { datas } = await getShippingWarehouse(this.platform, this.form.site)
      this.shippingWarehouseDatas = datas
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getFeedbackInfoByPages()
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.$refs?.form?.resetFields()
      this.queryClick()
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    async _querySiteList() {
      const { datas } = await querySiteList({ sellPlatformId: 8 })
      this.terraceData = datas
    },
    async getmailaccountinfo() {
      const { datas } = await listAllAccount()
      this.PlatformAccountList = datas
    },
    PlatformAccountChange() {
      this.queryClick()
    },
    PlatformsiteChange() {
      this.queryClick()
    },
    commentTypeChange() {
      this.queryClick()
    },
    // 默认查询
    async _getFeedbackInfoByPages() {
      try {
        this.TableLoading = true
        const { datas: { records = [], pager = {}}} = await getFeedbackInfoByPages(this.queryParameter)
        this.tableDatas = records
        this.pager.total = pager?.total || 0
      } finally {
        this.TableLoading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this.pager.current = 1
      this._getFeedbackInfoByPages()
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getFeedbackInfoByPages()
    }
  }
}
</script>

<style scoped lang="scss">
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
::v-deep .el-form-item{
  margin-bottom:10px;
  .el-select,.el-date-editor{
    width: 100%;
  }
}
</style>
